import Button from "components/UI/Button";
import React, {useContext} from "react";
import {ImageVideoPreview} from "components/UI/images";
import {VideoCanYouFeelIt} from "components/UI/videos";
import ButtonLink from "components/UI/ButtonLink";
import {MetamaskContext} from "provider/MetamaskProvider";
import {Link} from "react-router-dom";

const About = () => {
    const {isMetamaskOpened, addToWhitelist} = useContext(MetamaskContext);

    return (
        <section className="about">
            <div className="container">
                <div className="about__block">
                    <span className="text-center lead-block lead">This is gonna be YUGE!</span>
                    <h2 className="h2 text-center title-updater">CRYPTOTRUMP CLUB</h2>

                    <p className="text-center desc">
                        Welcome to CryptoTRUMP Club - a TREMENDOUS collection of 10,000 unique NFTs on the Ethereum
                        blockchain. Each CryptoTRUMP showcases the one-of-a-kind personality of the 45th President of
                        the United States, Donald J. Trump. Content, merch, swag, digital collectibles - our ratings are
                        gonna be YUGE! (Believe me.) Join today!
                    </p>

                    <div className="about__buttons">
                        <Button disabled={isMetamaskOpened} onClick={addToWhitelist} text="Join The Whitelist"/>

                        <Link className="button button--secondary disabled" to="mint">Mint Now</Link>

                        <ButtonLink link="https://opensea.io/collection/cryptotrump-club-1" type="secondary"
                                    text="Buy on Opensea"/>
                    </div>
                </div>

                <div className="about__quote">
                    <blockquote>
                        <p>
                            I remain excited by the decentralized nature of blockchain technology and NFTs because it
                            gives direct communication to people worldwide.
                        </p>

                        <p>
                            <b>CryptoTRUMP Club</b> gives a platform for those that love our great Nation. Enjoy the
                            Crypto-verse!
                        </p>
                    </blockquote>

                    <cite>First Lady Melania Trump</cite>
                </div>

                <div className="about__video">
                    <video poster={ImageVideoPreview} controls>
                        <source src={VideoCanYouFeelIt} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
};

export default About;
