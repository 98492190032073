import Home from "pages/Home";
import Mint from "pages/Mint";
import React, {useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

const Router = () => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <Routes>
            <Route path="/" element={<Home isLoading={isLoading} setIsLoading={setIsLoading}/>}/>
            <Route path="mint" element={<Mint/>}/>

            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

export default Router;
