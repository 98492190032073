import React, {ReactNode} from "react";

type ButtonProps = ButtonType & {
    onClick: () => void
    disabled?: boolean
}

const Button = ({text, type, size, onClick, icon, className, disabled}: ButtonProps) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`button ${generateButtonClassNames({type, size, className})}`}
        >
            {icon}{text}
        </button>
    );
};

export default Button;

export type ButtonType = {
    text: string
    type?: "secondary"
    size?: "large"
    icon?: ReactNode
    className?: string
}

export const generateButtonClassNames = ({type, size, className}: Pick<ButtonProps, "type" | "size" | "className">) => {
    const classNameType = type ? `button--${type} ` : " ";
    const classNameSize = size ? `button--${size} ` : " ";
    const customClassName = className ? className : " ";

    return classNameType + classNameSize + customClassName;
};
