import Card, {CardType} from "components/UI/Card";
import {ImageArt1, ImageArt2} from "components/UI/images";
import React, {useEffect, useState} from "react";
import {Endpoint} from "api/api";

const Art = () => {
    const [stats, setStats] = useState<CardType[]>([
        {id: "total_supply", name: "Items", desc: "10,000"},
        {id: "total_sales", name: "Sold", desc: "1,385"},
        {id: "total_volume", name: "Volume", desc: "1,500 ETH"},
        {id: "floor_price", name: "Floor", desc: "0.5 ETH"},
    ]);

    useEffect(() => {
        const getCollectionInfo = async () => {
            const collection = await Endpoint.getCollectionInfo();

            const {floor_price, total_volume, total_supply, total_sales} = collection.data;

            const statistic = {
                total_supply: addCommas(total_supply.toString()),
                total_sales: addCommas(total_sales.toString()),
                total_volume: addCommas(total_volume.toString()) + " ETH",
                floor_price: roundToTwoDecimalPlaces(Number(floor_price)) + " ETH",
            };

            type StatisticKey = keyof typeof statistic;

            setStats(stats.map(stat => ({...stat, desc: statistic[stat.id as StatisticKey]})))
        };

        getCollectionInfo();
    }, []);

    return (
        <section id="art" className="art">
            <div className="container">
                <div className="art__block">
                    <span className="lead-block lead">The Art (of the Deal?)</span>
                    <h2 className="h2 title-updater">Nobody knows digital art like Trump!</h2>

                    <p className="desc">
                        CryptoTRUMP Club is INCREDIBLE. Some say VERY INCREDIBLE. Each NFT is a 1/1 unique combination
                        of 97 hand-drawn attributes that showcase President Donald J. Trump's one-of-a-kind personality.
                    </p>

                    <Card type="row" card={stats}/>
                </div>

                <img src={ImageArt1} alt="art"/>
                <img src={ImageArt2} alt="art"/>
            </div>
        </section>
    );
};

export default Art;

function addCommas(numStr: string): string {
    let parts = numStr.split(".");
    let intStr = parts[0];
    let num = parseFloat(intStr);
    return addThousandSeparators(String(num));
}

function addThousandSeparators(numStr: string): string {
    return numStr.length <= 3 ? numStr: addThousandSeparators(numStr.slice(0, -3)) + "," + numStr.slice(-3);
}

function roundToTwoDecimalPlaces(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}
