import React, {useContext, useEffect, useRef} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {IconATM, IconATMMint, IconATMOpensea, IconATMWhitelist} from "components/UI/icons";
import {ImageHeroTitle, ImageTrumps, ImageTrumpsMob} from "components/UI/images";
import {useScrollEvent} from "hooks/useScrollEvent";
import {MetamaskContext} from "provider/MetamaskProvider";
import {Link} from "react-router-dom";

const Hero = () => {
    const {addToWhitelist, isMetamaskOpened} = useContext(MetamaskContext);

    gsap.registerPlugin(ScrollTrigger);
    const refATM = useRef<HTMLDivElement>(null);
    const refTitle = useRef<HTMLDivElement>(null);
    const refTrumps = useRef<HTMLDivElement>(null);

    const {currentScrollPosition} = useScrollEvent();

    useEffect(() => {
        if (refATM.current && refTrumps.current) {

            const heightATM = refATM.current.offsetHeight;
            const heightTrumps = refTrumps.current.offsetHeight;

            gsap.timeline({
                scrollTrigger: {
                    trigger: ".hero",
                    start: "bottom bottom",
                    end: "bottom top",
                    scrub: true,
                    pin: true,
                    markers: false,
                }
            })
                .fromTo(refATM.current, {bottom: -heightATM + 200}, {bottom: 0})
                .fromTo(refTrumps.current, {bottom: -heightTrumps}, {bottom: 0})
        }
    }, [refATM, refTrumps]);

    useEffect(() => {
        if (refTitle.current) {
            refTitle.current.style.bottom = currentScrollPosition + "px"
        }
    }, [currentScrollPosition]);

    return (
        <section className="hero">
            <div ref={refTitle} className="hero__title">
                <img src={ImageHeroTitle} alt="title"/>
            </div>

            <div className="hero__atm" ref={refATM}>
                <span>
                    <IconATM/>
                </span>

                <span className={`${isMetamaskOpened ? "disabled" : ""}`} onClick={addToWhitelist}>
                    <IconATMWhitelist/>
                </span>

                <span className="disabled">
                    <Link to="mint"><IconATMMint/></Link>
                </span>

                <span>
                    <a href="https://opensea.io/collection/cryptotrump-club-1" target="_blank" rel="noreferrer">
                    <IconATMOpensea/>
                    </a>
                </span>
            </div>

            <div ref={refTrumps} className="hero__trumps">
                <picture>
                    <source media="(max-width:525px)" srcSet={ImageTrumpsMob}/>
                    <img src={ImageTrumps} alt="trump"/>
                </picture>
            </div>
        </section>
    );
};

export default Hero;
