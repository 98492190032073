import React, {useContext, useEffect} from "react";
import Header from "components/Header";
import MintImages from "components/mint/MintImages";
import MintContract from "components/mint/MintContract";
import {ImageMint1, ImageMint2, ImageMint3} from "components/UI/images";
import Button from "components/UI/Button";
import {MetamaskContext} from "provider/MetamaskProvider";
import {ModalContext} from "provider/ModalProvider";

const Mint = () => {
    const {
        address,
        provider,
        chainIdEth,
        isMetamaskOpened,
        contractAddress,

        connectWallet,
        changeNetwork,
    } = useContext(MetamaskContext);
    const {setIsOpen, setInfo} = useContext(ModalContext);

    useEffect(() => {
        if (!contractAddress) {
            setInfo({title: "Contract Error!", text: "Mint will be start soon...", error: false});
            setIsOpen(true);
        }
    }, []);

    return (
        <div className="mint">
            <Header isAnchorHome={false}/>

            <div className="container mint__container">
                <MintImages
                    speed={3000}
                    images={[ImageMint1, ImageMint2, ImageMint3]}
                />

                <div className="mint__content">
                    <span className="lead-block lead">This is gonna be YUGE!</span>
                    <h2 className="h2 title-updater">CryptoTrump Club</h2>

                    <p className="desc">
                        This collection of 10,000 NFTs serves to represent all sides of the 45th President of the United
                        States.
                    </p>

                    {
                        contractAddress && <div className="mint__wrapper">
                            {
                                address
                                    ? <>
                                        {
                                            provider && provider.chainId === chainIdEth
                                                ? <MintContract/>
                                                : <Button
                                                    disabled={isMetamaskOpened}
                                                    text="Change Metamask Network"
                                                    onClick={changeNetwork}
                                                />
                                        }
                                    </>
                                    : <Button
                                        disabled={isMetamaskOpened}
                                        text="Connect Wallet"
                                        onClick={connectWallet}
                                    />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Mint;
