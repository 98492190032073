import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {ImageTeam1, ImageTeam2, ImageTeam3} from "components/UI/images";
import {Pagination} from "swiper";

const Team = () => {
    return (
        <section id="team" className="team">
            <div className="container">
                <div className="team__block">
                    <span className="text-center lead-block lead">Our Team</span>
                    <h2 className="h2 text-center title-updater">Very Stable Geniuses</h2>

                    <p className="text-center desc">
                        Our founders are a big, beautiful group of men and women who work tirelessly to keep the project
                        growing and the community exciting so that together we can MAKE. NFTs. GREAT. AGAIN.
                    </p>
                </div>

                <div className="team__slider">
                    <Swiper
                        pagination={{clickable: true}}
                        modules={[Pagination]}

                        slidesPerView={1}
                        spaceBetween={60}
                        breakpoints={{
                            "1024": {
                                slidesPerView: 3,
                                spaceBetween: 60,
                            },
                            "768": {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {
                            team.map(person => <SwiperSlide key={person.id}>
                                <div className="team__card">
                                    <img src={person.image} width="312" height="312" alt="team"/>
                                    <p className="lead">{person.name}</p>
                                    <p><b>{person.pos}</b></p>
                                    <span className="team__card-separator"/>
                                    <p>{person.desc}</p>
                                </div>
                            </SwiperSlide>)
                        }
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Team;

const team = [
    {
        id: 1,
        image: ImageTeam1,
        name: "The Big Cheese",
        pos: "Our Founder",
        desc: "He has years of experience in finance, startups, and politics. He knows how to create a thriving community primed for growth.",
    },
    {
        id: 2,
        image: ImageTeam2,
        name: "Orange Man",
        pos: "The Brains",
        desc: "He has deep knowledge of digital advertising and NFT marketing (\"millions and millions....\"). Built and manages our Discord community and social media channels.",
    },
    {
        id: 3,
        image: ImageTeam3,
        name: "Covfefe",
        pos: "The Creative",
        desc: "He has a wealth of experience as a graphic designer and illustrator. All illustrations and merchandise designs come from him.",
    },
];
