import React, {useEffect, useRef, useState} from "react";
import Menu from "./Menu";
import Button from "./UI/Button";
import {ImageLogo} from "./UI/images";
import ButtonLink from "./UI/ButtonLink";
import {Link} from "react-router-dom";
import { useScrollEvent } from "hooks/useScrollEvent";

type HeaderProps = {
    isAnchorHome: boolean
}

const Header = ({isAnchorHome}: HeaderProps) => {
    const ref = useRef<HTMLElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHeaderHidden, setIsHeaderHidden]  = useState(false);

    const {currentScrollPosition, prevScrollPosition} = useScrollEvent();

    useEffect(() => {
        setIsMenuOpen(false);

        if (ref && ref.current && ref.current.offsetHeight !== undefined) {

            if (prevScrollPosition >= currentScrollPosition) {
                setIsHeaderHidden(false);
                return;
            }

            setIsHeaderHidden(currentScrollPosition >= ref.current.offsetHeight);
        }
    }, [currentScrollPosition, ref]);

    return (
        <header ref={ref} style={{opacity: isHeaderHidden ? 0: 1}} className="header">
            <div className="container header__container">
                <Button className="header__btn" text="Menu" onClick={() => setIsMenuOpen(true)}/>

                <Link to="/">
                    <img className="header__logo" src={ImageLogo} width="90" height="60" alt="logo"/>
                </Link>

                <ButtonLink className="header__btn" text="Store" link="https://newimagedesigns.com/CryptoTrump/shop/home"/>
            </div>

            <Menu home={isAnchorHome} isOpen={isMenuOpen} setIsOpen={setIsMenuOpen}/>
        </header>
    );
};

export default Header;
