import React from "react";
import Socials from "./Socials";

const Footer = () => {
    return (
        <footer id="contact" className="footer">
            <div className="container">
                <div className="footer__block">
                    <span className="text-center lead-block lead">Don't miss a thing!</span>
                    <h2 className="h2 text-center title-updater">Join us in the MAGAverse</h2>

                    <Socials type="row"/>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
