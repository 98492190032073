import {IconPhase1, IconPhase2, IconPhase3} from "components/UI/icons";
import React from "react";
import {ImageRoadmap1, ImageRoadmap2, ImageRoadmap3, ImageRoadmap4} from "components/UI/images";

const Roadmap = () => {
    return (
        <section id="roadmap" className="roadmap">
            <div className="container">
                <div className="roadmap__block">
                    <span className="text-center lead-block lead">Road Map</span>
                    <h2 className="h2 text-center title-updater">SO MUCH WINNING</h2>
                </div>

                <div className="roadmap__phaser">
                    {
                        roadmapPhaser.map(phase => <div key={phase.id} className="roadmap__phase">
                            <div className="roadmap__step">
                                <div className="roadmap__step-point"/>
                            </div>
                            <div className="roadmap__content">
                                <span className="lead-block lead">{phase.title}</span>
                                <span className="roadmap__content-title">{phase.icon}</span>

                                <ul className="roadmap__list">
                                    {
                                        phase.list.map(item => <li key={item.title}>
                                            <b>{item.title}</b>
                                            <p>{item.text}</p>
                                        </li>)
                                    }
                                </ul>
                            </div>
                        </div>)
                    }
                </div>
            </div>

            <img src={ImageRoadmap2} alt="art"/>
            <img src={ImageRoadmap1} alt="art"/>

            <img src={ImageRoadmap3} alt="art"/>
            <img src={ImageRoadmap4} alt="art"/>
        </section>
    );
};

export default Roadmap;

const roadmapPhaser = [
    {
        id: 1,
        title: "Growth",
        icon: <IconPhase1/>,
        list: [
            {
                title: "Influencer Growth",
                text: "Continue to grow our influencer pool to keep the hype and floor prices booming.",
            },
            {
                title: "Merch",
                text: "Produce exclusive merch available only to owners",
            },
            {
                title: "Discord Community",
                text: "Members can interact with VIPs and/or celebrities through community events, like AMAs.",
            },
        ],
    },
    {
        id: 2,
        title: "Events",
        icon: <IconPhase2/>,
        list: [
            {
                title: "Live Private Events",
                text: "Host exclusive events with celebrities and members across the USA.",
            },
            {
                title: "Event Give-Aways",
                text: "Give owners a chance to win access to big political events, like rallies, shows or conventions.",
            },
            {
                title: "Exclusive NFT Collections",
                text: "Give owners exclusive/early access to upcoming MAGAVERSE NFTs.",
            },
        ],
    },
    {
        id: 3,
        title: "Metaverse",
        icon: <IconPhase3/>,
        list: [
            {
                title: "Land Acquisition",
                text: "Purchase a plot of metaverse property to host exclusive virtual events for members only.",
            },
        ],
    },
];
