import React, {ChangeEvent, Dispatch, SetStateAction, useState} from "react";

type CounterProps = {
    max: number
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const Counter = ({current, setCurrent, max}: CounterProps) => {
    const [min] = useState(1);

    const handleChangeCurrent = (e: ChangeEvent<HTMLInputElement>) => {
        const newCurrent = +e.target.value;

        if (+newCurrent && (newCurrent <= max && newCurrent >= min)) {
            setCurrent(+e.target.value);
        }
    };

    const handleAddCounter = () => {
        if (current < max) setCurrent(current + 1);
    };

    const handleRemoveCounter = () => {
        if (current > min) setCurrent(current - 1);
    };

    return (
        <div className="counter">
            <button
                disabled={current === min}
                onClick={handleRemoveCounter}
                className="counter__btn counter__btn--remove"
                children="remove"
            />
            <input className="counter__input" value={current} onChange={handleChangeCurrent} type="text"/>
            <button
                disabled={current === max}
                onClick={handleAddCounter}
                className="counter__btn counter__btn--add"
                children="add"
            />
        </div>
    );
};

export default Counter;
