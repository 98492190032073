import React from "react";
import {useScrollEvent} from "hooks/useScrollEvent";

type ScrollSliderProps = {
    speed: number
}

const ScrollSlider = ({speed}: ScrollSliderProps) => {
    const {currentScrollPosition} = useScrollEvent();

    return (
        <div className="scroll-slider">
            <div style={{backgroundPosition: currentScrollPosition / speed}}/>
            <div style={{backgroundPosition: -currentScrollPosition / speed}}/>
        </div>
    );
};

export default ScrollSlider;
