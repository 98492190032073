import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {ProviderType, useProvider} from "hooks/useProvider";
import {useSwitchNetwork} from "hooks/useSwitchNetwork";
import contractABI from "ethereum/contract.json";
import {Endpoint} from "api/api";
import {ModalContext} from "./ModalProvider";

export type ChildrenType = {
    children: JSX.Element | React.ReactNode
}

const MetamaskProvider = ({children}: ChildrenType) => {
    const {setInfo, setIsOpen, setIsOpenError} = useContext(ModalContext);

    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS as string;
    const chainIdEth = process.env.REACT_APP_ETH_CHAIN as ChainIdType;

    const provider = useProvider(window.ethereum);
    const switchNetwork = useSwitchNetwork(provider, chainIdEth);

    const [address, setAddress] = useState("");
    const [isMetamaskOpened, setIsMetamaskOpened] = useState(false);

    const connectWallet = async () => {
        try {
            if (!provider) {
                window.open(`https://metamask.app.link/dapp/${window.location.host}`);
                return;
            }

            setIsMetamaskOpened(true);

            const accounts: string[] = await provider.request({method: "eth_requestAccounts"});
            const account = accounts[0];

            setAddress(account);
            return account;
        } catch (error: any) {
            console.log(error);
        } finally {
            setIsMetamaskOpened(false);
        }
    };

    const changeNetwork = async () => {
        try {
            if (!provider) {
                window.open(`https://metamask.app.link/dapp/${window.location.host}`);
                return;
            }

            setIsMetamaskOpened(true);

            await switchNetwork();
        } catch (error: any) {
            console.log(error);
        } finally {
            setIsMetamaskOpened(false);
        }
    };

    const isWalletConnected = async () => {
        try {
            if (!provider) return;

            setIsMetamaskOpened(true);

            const accounts = await provider.request({method: "eth_accounts"});

            if (accounts.length !== 0) setAddress(accounts[0]);
        } catch (error) {
            console.log(error);
        } finally {
            setIsMetamaskOpened(false);
        }
    };

    const addToWhitelist = async () => {
        try {
            setIsMetamaskOpened(true);

            let account = address;

            if (!account) {
                account = await connectWallet() as string;
            }

            if (account) {
                try {
                    const response = await Endpoint.addWhitelist(account);

                    if (response.data.success) {
                        setInfo({
                            title: "Whitelist Success!",
                            text: "Congratulations, you are in the whitelist, wait for private mint.",
                            error: false,
                        });
                    }

                    setIsOpen(true);
                } catch (e: any) {
                    console.log(e);
                    if (e.code === "ERR_BAD_REQUEST") {
                        setInfo({title: "Whitelist Error!", text: "Looks like you are already signed up for the whitelist.", error: true});
                        setIsOpen(true);
                    } else {
                        setIsOpenError(false);
                    }
                }
            }
        } finally {
            setIsMetamaskOpened(false);
        }
    };

    useEffect(() => {
        isWalletConnected();
    }, []);

    useEffect(() => {
        if (!provider) return;

        const changeAddress = (address: string[]) => {
            if (address.length !== 0) {
                setAddress(address[0])
            } else {
                setAddress("");
            }
        };

        provider.on("accountsChanged", changeAddress);
    }, []);

    useEffect(() => {
        if (!provider) return;

        const changeChain = () => {
            window.location.reload();
        };

        provider.on("chainChanged", changeChain);
    }, []);

    const context: MetamaskContextType = {
        address,
        provider,
        contractABI,
        contractAddress,
        isMetamaskOpened,
        chainIdEth,

        addToWhitelist,
        setIsMetamaskOpened,
        connectWallet,
        changeNetwork,
    };

    return (
        <MetamaskContext.Provider value={context}>
            {children}
        </MetamaskContext.Provider>
    )
};

export default MetamaskProvider;

export const MetamaskContext = createContext({} as MetamaskContextType);

type MetamaskContextType = {
    address: string
    provider: ProviderType
    isMetamaskOpened: boolean
    setIsMetamaskOpened: Dispatch<SetStateAction<boolean>>
    connectWallet: () => Promise<string | undefined>
    addToWhitelist: () => {}
    changeNetwork: () => void
    chainIdEth: ChainIdType

    contractABI: any[]
    contractAddress: string
}

export type ChainIdType = "0x1" | "0xaa36a7"
