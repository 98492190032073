import React from 'react';

type CardProps = {
    card: CardType[]
    type: "row" | "column"
}

const Card = ({card, type}: CardProps) => {
    const classNameType = type ? `card--${type}` : "";

    return (
        <ul className={`card ${classNameType}`}>
            {
                card.map(item => <li key={item.id}>
                    <p>{item.name}</p>
                    <span className="card__separator"/>
                    <span className="h3">{item.desc}</span>
                </li>)
            }
        </ul>
    );
};

export default Card;

export type CardType = { id: string | number, name: string, desc: string }
