import React from "react";
import {ButtonType, generateButtonClassNames} from "./Button";

type ButtonLinkProps = ButtonType & { link: string }

const ButtonLink = ({text, type, link, icon, className, size}: ButtonLinkProps) => {
    return (
        <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={`button ${generateButtonClassNames({type, size, className})}`}
        >
            {icon}{text}
        </a>
    );
};

export default ButtonLink;
