import {useEffect, useState} from "react";

export const useScrollEvent = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [prevScrollPosition, setPrevScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        setPrevScrollPosition(scrollPosition);
    }, [scrollPosition]);

    return {currentScrollPosition: scrollPosition, prevScrollPosition: prevScrollPosition};
};
