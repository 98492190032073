import React from "react";
import ButtonLink from "./UI/ButtonLink";
import {IconDiscord, IconInstagram, IconTwitter, IconFacebook} from "./UI/icons";

type SocialsProps = {
    type: "row" | "column"
}

const Socials = ({type}: SocialsProps) => {
    const classNameType = type ? `socials--${type}` : "";

    return (
        <ul className={`socials ${classNameType}`}>
            {
                socials.map(item => <li key={item.id}>
                    <ButtonLink className="socials__link" link={item.link} icon={item.icon} text={item.text}/>
                </li>)
            }
        </ul>
    );
};

export default Socials;

const socials = [
    {id: 1, text: "Discord", link: "https://discord.com/invite/cryptotrumpclub", icon: <IconDiscord/>},
    {id: 2, text: "Instagram", link: "https://www.instagram.com/realcryptotrumps/", icon: <IconInstagram/>},
    {id: 3, text: "Twitter", link: "https://twitter.com/crypto_trumps", icon: <IconTwitter/>},
    {id: 4, text: "Facebook", link: "https://www.facebook.com/CryptoTrumps", icon: <IconFacebook/>},
];
