import React, {useContext} from "react";
import {ModalContext} from "provider/ModalProvider";
import { IconError } from "./icons";

const Modal = () => {
    const {isOpen, setIsOpen, info} = useContext(ModalContext);
    const {text, title, error} = info;

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div className={`modal ${isOpen ? "open": ""} ${error ? "error": ""}`}>
            <div className="modal__inner">
                <button onClick={handleClose} className="modal__btn-close">close</button>
                <div className="modal__header">
                    {error && <IconError/>}
                    <p className="lead modal__title">{title}</p>
                </div>

                <div className="modal__body">
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
};

export default Modal;
