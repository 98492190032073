import React from "react";
import Marquee from "react-fast-marquee";
import {IconTickerText} from "./icons";

type RunlineProps = {
    speed: number
}

const Runline = ({speed}: RunlineProps) => {
    return (
        <Marquee speed={speed} className="runline">
            <IconTickerText/>
        </Marquee>
    );
};

export default Runline;
