import React, {Dispatch, SetStateAction, useEffect} from "react";
import {ImageTrumpPlane} from "./UI/images";

type LoaderProps = {
    isLoading: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
}

const Loader = ({isLoading, setIsLoading}: LoaderProps) => {
    useEffect(() => {
        document.body.classList[isLoading ? "add": "remove"]("overflow-hidden");
    }, [isLoading]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`loader ${isLoading ? "open" : ""}`}>
            <img className="loader__img" src={ImageTrumpPlane} width="262" height="200" alt="Trump Plane"/>
            <p className="loader__text h3 title-updater">Loading...</p>

            <div className="loader__progress">
                <div className="loader__bar"/>
            </div>
        </div>
    );
};

export default Loader;
