import React, {Dispatch, SetStateAction, useEffect} from "react";
import Socials from "./Socials";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

type MenuProps = {
    isOpen: boolean
    home: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

const Menu = ({isOpen, home, setIsOpen}: MenuProps) => {
    useEffect(() => {
        document.body.classList[isOpen ? "add" : "remove"]("overflow-hidden");
    }, [isOpen]);

    return (
        <div className={`menu ${isOpen ? "open" : ""}`}>
            <div className="menu__wrapper">
                <button onClick={() => setIsOpen(false)} className="menu__btn-close">close</button>

                <div className="menu__inner">
                    <ul className="menu__list">
                        <li>
                            <Link className="h2 menu__link title-updater disabled" to="/mint">Mint</Link>
                        </li>

                        <li>
                            <HashLink className="h2 menu__link title-updater" smooth={home} to="/#art">The Art</HashLink>
                        </li>

                        <li>
                            <HashLink className="h2 menu__link title-updater" smooth={home} to="/#roadmap">Roadmap</HashLink>
                        </li>
                    </ul>

                    <ul className="menu__list">
                        <li>
                            <HashLink className="h2 menu__link title-updater" smooth={home} to="/#team">Our Team</HashLink>
                        </li>

                        <li>
                            <HashLink className="h2 menu__link title-updater" smooth={home} to="/#contact">Contact</HashLink>
                        </li>
                    </ul>
                </div>

                <div className="menu__socials">
                    <Socials type="column"/>
                </div>
            </div>
        </div>
    );
};

export default Menu;
