import React, {useEffect, useState} from "react";

type MintImagesProps = {
    speed: number
    images: [string, string, string]
};

const MintImages = ({speed, images}: MintImagesProps) => {
    const [isStart, setIsStart] = useState(false);
    const [intervalTimer, setIntervalTimer] = useState<number>(0);
    const [positions, setPositions] = useState<ElementPosition[]>([]);

    const desktopSetting: ElementPosition[] = [
        {pos: 220, current: 2, size: 600},
        {pos: -50, current: 1, size: 520},
        {pos: 650, current: 0, size: 520},
    ];

    const mobileSetting: ElementPosition[] = [
        {pos: -150, current: 2, size: 300},
        {pos: -275, current: 1, size: 260},
        {pos: 65, current: 0, size: 260},
    ];

    useEffect(() => {
        const isDesktop = window.innerWidth > 768;

        setPositions(isDesktop ? desktopSetting : mobileSetting);

        setIsStart(true);
    }, []);

    useEffect(() => {
        if (!isStart) return;

        setPositions([...positions.slice(1), ...positions.slice(0, 1)]);
    }, [intervalTimer, isStart]);

    useEffect(() => {
        if (!isStart) return;

        const timer = setInterval(() => setIntervalTimer(Date.now()), speed);

        return () => clearTimeout(timer);
    }, [isStart]);

    return (
        <div className="mint__images">
            {
                positions.length === images.length && images.map((img, index) => {
                    const {pos: right, current, size} = positions[index];

                    return <img
                        key={index}
                        src={img}
                        style={{right, zIndex: current}}
                        width={size}
                        height={size}
                        alt="trump"
                    />
                })
            }
        </div>
    );
};

export default MintImages;

type ElementPosition = {
    pos: number
    size: number
    current: 2 | 0 | 1
}
