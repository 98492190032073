import Footer from "components/Footer";
import Header from "components/Header";
import About from "components/sections/About";
import Art from "components/sections/Art";
import Community from "components/sections/Community";
import Hero from "components/sections/Hero";
import Roadmap from "components/sections/Roadmap";
import Team from "components/sections/Team";
import Runline from "components/UI/Runline";
import React, {Dispatch, SetStateAction} from "react";
import Loader from "components/Loader";

type HomeProps = {
    isLoading: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
}

const Home = ({isLoading, setIsLoading}: HomeProps) => {
    return (
        <>
            {
                isLoading
                    ? <Loader isLoading={isLoading} setIsLoading={setIsLoading}/>
                    : <>

                        <Header isAnchorHome={true}/>

                        <main>
                            <Hero/>

                            <div className="background">
                                <About/>

                                <Runline speed={100}/>

                                <Community/>

                                <Art/>
                            </div>

                            <Roadmap/>

                            <div className="background">
                                <Team/>
                            </div>

                            <Footer/>
                        </main>
                    </>
            }
        </>
    );
};

export default Home;
