import React, {useContext, useEffect, useState} from "react";
import Button from "components/UI/Button";
import {MetamaskContext} from "provider/MetamaskProvider";
import Counter from "components/UI/Counter";
import {ModalContext} from "provider/ModalProvider";
import {ethers} from "ethers";
import {MerkleTree} from "merkletreejs";
import keccak256 from "keccak256";
import whitelist from "ethereum/whitelist.json";

const MintContract = () => {
    const {address, provider, contractAddress, contractABI, setIsMetamaskOpened, isMetamaskOpened} = useContext(MetamaskContext);
    const {setIsOpen, setInfo} = useContext(ModalContext);

    const [price, setPrice] = useState<number>();
    const [countWalletNFT, setCountWalletNFT] = useState<CountWalletNFTsType>(null);

    const [count, setCount] = useState<number>(1);
    const [max, setMax] = useState<number>(10);

    const BigIntToNumber = (bigInt: bigint): number => {
        return +bigInt.toString();
    };

    const BigIntToEther = (bigInt: bigint): number => {
        return +ethers.formatUnits(bigInt, "ether");
    };

    const initPrice = async () => {
        const {contract} = await getContract();

        const isPrivate = await contract.isPrivateSale();

        const priceContractName = isPrivate ? "getPricePrivate" : "getPricePublic";

        const bigIntPrice: bigint = await contract[priceContractName](count);

        const price = BigIntToEther(bigIntPrice);

        return {number: price, bigInt: bigIntPrice};
    };

    const initCountWalletNft = async () => {
        const {contract} = await getContract();

        const currentMintedNFTs = BigIntToNumber(await contract.getCountWalletNft());

        const isPrivate = await contract.isPrivateSale();

        const maxCountMintFuncName = isPrivate ? "_maxCountMintPrivate" : "_maxCountMintPublic";
        const maxCountMint = BigIntToNumber(await contract[maxCountMintFuncName]());

        if (currentMintedNFTs >= maxCountMint) {
            setCountWalletNFT("limit");
        } else {
            setCountWalletNFT(currentMintedNFTs);
            setMax(maxCountMint - currentMintedNFTs);
        }
    };

    const getContract = async () => {
        const BrowserProvider = new ethers.BrowserProvider(provider);
        const signer = await BrowserProvider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        return {contract, BrowserProvider};
    };

    const mint = async () => {
        try {
            setIsMetamaskOpened(true);

            if (!provider) {
                setIsOpen(true);
                setInfo({text: "Please install Metamask.", title: "Metamask Error!", error: true})
                return;
            }

            const {contract, BrowserProvider} = await getContract();

            const isPaused = await contract.paused();

            if (isPaused) {
                setIsOpen(true);
                setInfo({text: "Sale not started yet.", title: "Contract Paused!", error: false});
                return;
            }

            const balance = BigIntToEther(await BrowserProvider.getBalance(address));

            if (!balance) {
                setIsOpen(true);
                setInfo({text: "Please add funds to your Metamask wallet to mint.", title: "Insufficient Funds!", error: true});
                return;
            }

            const {number: priceNumber, bigInt: priceBigInt} = await initPrice();

            if (priceNumber > balance) {
                setIsOpen(true);
                setInfo({text: "Please add funds to your Metamask wallet to mint.", title: "Insufficient Funds!", error: true});
                return;
            }

            const totalNFTSupply = BigIntToNumber(await contract.totalNFTSupply());
            const totalSupplied = BigIntToNumber(await contract.totalSupply());

            if (totalNFTSupply < totalSupplied + count) {
                setIsOpen(true);
                setInfo({text: "Total supply exceeded. Use less amount.", title: "Contract Error!", error: true});
                return;
            }

            const isPrivate = await contract.isPrivateSale();
            let status;

            const overrides = {
                value: priceBigInt.toString(),
                gasLimit: 300_000,
            };

            if (isPrivate) {
                const {verify, proof} = checkMerkleTree(address, whitelist);

                if (verify) {
                    const trx = await contract.privateMint(count, proof, overrides);
                    await trx.wait();
                    const receipt = await trx.wait();
                    status = receipt.status;
                } else {
                    setIsOpen(true);
                    setInfo({text: "You are not at the Whitelist.", title: "Whitelist Error!", error: true});
                    return;
                }
            } else {
                const trx = await contract.publicMint(count, overrides);
                await trx.wait();
                const receipt = await trx.wait();
                status = receipt.status;
            }

            if (status === 1) {
                setInfo({text: "Congratulations! You have successfully minted, welcome to the big beautiful family.", title: "Mint Successful!", error: false});

                if (max - count === 0) {
                    setCountWalletNFT("limit");
                } else {
                    setMax(max - count);
                    setCount(1);
                }
            } else {
                setInfo({text: "Please ensure all transaction information is correct and that you are signed in to your Metamask wallet.", title: "Mint Error!", error: true});
            }

            setIsOpen(true);
        } catch (error) {
            console.log(error);
        } finally {
            setIsMetamaskOpened(false);
        }
    };

    useEffect(() => {
        const getPrice = async () => {
            const price = await initPrice();
            setPrice(price.number);
        };

        getPrice();
    }, [address, count]);

    useEffect(() => {
        initCountWalletNft();
    }, [address]);

    return (
        <>
            {
                price && <>
                    <p>Price</p>
                    <p className="lead mint__price">{`${price} ETH`}</p>
                </>
            }

            {
                !isMetamaskOpened ? <>
                    {
                        countWalletNFT !== null && <>
                            {
                                countWalletNFT === "limit"
                                    ? <p>Your limit is exhausted for this account.</p>
                                    : <div className="mint__main">
                                        <Counter current={count} setCurrent={setCount} max={max}/>

                                        <Button text="Mint Now" onClick={mint}/>
                                    </div>
                            }
                        </>
                    }
                </> : <div className="mint__loader"/>
            }

            <div className="mint__account">
                <p>Account</p>
                <p className="mint__address lead">{address}</p>
            </div>
        </>
    );
};

export default MintContract;

type CountWalletNFTsType = number | "limit" | null

export const checkMerkleTree = (address: string, whitelist: string[]) => {
    const leaf = keccak256(address) as unknown as string;
    const leafNodes = MerkleTree.unmarshalLeaves(whitelist);
    const tree = new MerkleTree(leafNodes, keccak256, {sortPairs: true});
    const proof = tree.getHexProof(leaf);
    const root = tree.getHexRoot();
    const verify = tree.verify(proof, leaf, root);

    return {
        leaf,
        tree,
        proof,
        verify,
        root,
        leafNodes,
    };
};

