import axios, {AxiosResponse} from "axios";

type EndpointType = {
    addWhitelist(address: string): Promise<AxiosResponse<any>>
    getCollectionInfo(): Promise<AxiosResponse<CollectionInfo>>
}

export const Endpoint: EndpointType = {
    addWhitelist: (address: string) => {
        return axios.post(ADD_WHITELIST, {address});
    },

    getCollectionInfo: () => {
        return axios.get(GET_COLLECTION_INFO);
    },
};

type CollectionInfo = {
    total_sales: string
    floor_price: string
    total_volume: string
    total_supply: string
}

const baseUrl = "https://crypto-trump-back.herokuapp.com/api/v1/main/";
const ADD_WHITELIST = baseUrl + "add-whitelist";
const GET_COLLECTION_INFO = baseUrl + "get-collection-info";
