import React from "react";
import ScrollSlider from "components/UI/ScrollSlider";

const Community = () => {
    return (
        <section className="community">
            <div className="container">
                <div className="community__block">
                    <span className="text-center lead-block lead">Web 3.0 Community</span>
                    <h2 className="h2 text-center title-updater">Hands down the best NFT community!</h2>

                    <p className="text-center desc">
                        Join us in our pursuit to dominate the NFT space with a community like nobody's ever seen!
                        Explore the site and join our Discord to learn more about our Road Map. Hurry before the wall
                        gets 10 feet higher!
                    </p>
                </div>
            </div>

            <ScrollSlider speed={5}/>
        </section>
    );
};

export default Community;
