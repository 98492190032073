import React, {useContext} from "react";
import {ImageErrorTrump} from "./images";
import Button from "./Button";
import {ModalContext} from "provider/ModalProvider";

const ModalError = () => {
    const {isOpenError, setIsOpenError} = useContext(ModalContext);

    const handleClose = () => {
        setIsOpenError(false);
    };

    return (
        <div className={`modal modal--error ${isOpenError ? "open" : ""}`}>
            <div className="modal__inner">
                <button onClick={handleClose} className="modal__btn-close">close</button>
                <div className="modal__header">
                    <img src={ImageErrorTrump} alt="trump"/>
                </div>

                <div className="modal__body">
                    <h4 className="modal__title text-center lead">If I were President it would work!</h4>
                    <p className="text-center">We are so popular that the server can’t handle all requests! Try again later.</p>
                    <Button text="Ok" onClick={handleClose}/>
                </div>
            </div>
        </div>
    );
};

export default ModalError;
