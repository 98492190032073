import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {ChildrenType} from "./MetamaskProvider";

const ModalProvider = ({children}: ChildrenType) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenError, setIsOpenError] = useState(false);
    const [info, setInfo] = useState<ModalInfoType>({error: false, title: "", text: ""});

    const context = {
        isOpen,
        setIsOpen,

        isOpenError,
        setIsOpenError,

        info,
        setInfo,
    };

    return (
        <ModalContext.Provider value={context}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;

export const ModalContext = createContext({} as ModalContextType);

type ModalContextType = {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>

    isOpenError: boolean
    setIsOpenError: Dispatch<SetStateAction<boolean>>

    info: ModalInfoType
    setInfo: Dispatch<SetStateAction<ModalInfoType>>
}

export type ModalInfoType = {
    error: boolean
    title: string
    text: string
}
