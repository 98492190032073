import React, {useEffect} from "react";
import "./assets/styles/index.scss";
import Router from "router/Router";
import MetamaskProvider from "./provider/MetamaskProvider";
import ModalProvider from "./provider/ModalProvider";
import Modal from "./components/UI/Modal";
import {Buffer} from "buffer";
import ModalError from "./components/UI/ModalError";

const App = () => {
    useEffect(() => {
        window.Buffer = Buffer;
    }, []);

    return (
        <ModalProvider>
            <MetamaskProvider>
                <Router/>

                <Modal/>
                <ModalError/>
            </MetamaskProvider>
        </ModalProvider>
    );
};

export default App;
