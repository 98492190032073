import {ChainIdType} from "provider/MetamaskProvider";
import {ProviderType} from "./useProvider";

export const useSwitchNetwork: SwitchNetworkCallbackType = (provider, chainId) => {
    return async () => {
        if (!provider) return;

        try {
            await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: chainId,
                }],
            });
        } catch (error) {
            console.log(error);
        }
    };
};

export type SwitchNetworkCallbackType = (provider: ProviderType, chainId: ChainIdType) => () => Promise<void>
